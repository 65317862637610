import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AuthService } from '../services/auth.service';

@Injectable()
export class JwtInteceptor implements HttpInterceptor {
    constructor(private authService: AuthService) {}

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        // add authorization header with jwt token if available
        let currentUser = this.authService.currentUserValue;
        var shopId = '';
        if(localStorage.getItem('shopId')) {
            shopId = localStorage.getItem('shopId');
        }
        if (currentUser && currentUser.tokens.access_token) {
            request = request.clone({
                setHeaders: {
                    Authorization: `Bearer ${currentUser.tokens.access_token}`,
                    shopId: shopId
                }
            });
        }

        return next.handle(request);
    }
}