<nb-layout>
    <nb-layout-column class="center">
        <nb-card>
            <nb-card-body>
                <h3 class="text-center">HB Merchant Australia</h3>

                <form [formGroup]="loginForm" (ngSubmit)="onSubmit()">
                    
                    <div class="row mb-3">
                        <div class="col-md-12">
                            <span class="text-hint">Email</span>
                        </div>
                        <div class="col-md-12">
                            <input 
                                type="email" 
                                nbInput
                                fullWidth
                                placeholder="Email"
                                email="true"
                                formControlName="email">
                        </div>
                    </div>
            
                    <div class="row mb-3">
                        <div class="col-md-12">
                            <span class="text-hint">Password</span>
                        </div>
                        <div class="col-md-12">
                            <input 
                                type="password" 
                                nbInput
                                fullWidth 
                                placeholder="Password"
                                formControlName="password">
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-6">
                            <button 
                                nbButton 
                                fullWidth 
                                [nbSpinner]="loading"
                                status="primary" 
                                type="submit">Login</button>
                        </div>
                        <div class="col-md-6">
                            <button 
                                nbButton 
                                fullWidth 
                                [nbSpinner]="loading"
                                status="warning" 
                                type="button"
                                (click)="backToStore()">Back to Happy Beauty</button>
                        </div>
                    </div>
                    <div class="row mt-4">
                        <div class="col-lg-12">
                            <p>Note: Happybeauty merchant account is same as customer account. If you have registered a customer account, please use your customer login details for merchant portal here.</p>
                        </div>
                    </div>
                </form>

            </nb-card-body>

            <nb-card-footer>
                <div class="row">
                    <div class="col-md-12 text-center p-2">
                        Copyright Happy Beauty Australia
                    </div>
                </div>
            </nb-card-footer>

        </nb-card>
        
    </nb-layout-column>
</nb-layout>
