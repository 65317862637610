import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { AuthService } from '../shared/services/auth.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  loginForm: FormGroup;
  loading = false;

  constructor(
    private authService: AuthService,
    private router: Router,
  ) { }

  ngOnInit(): void {
    this.initLoginForm();
  }

  initLoginForm() {
    this.loginForm = new FormGroup({
      'email': new FormControl(null, Validators.required),
      'password': new FormControl(null, Validators.required),
    });
  }

  onSubmit() {
    this.loading = true;
    var form = this.loginForm.value
    if(form['email'] == "" || form['password'] == "") {
      window.alert("email and password can not be empty !");
      this.loading = false;
    } else {
      if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(form['email']))
      {
        this.authService.login(form).subscribe(
          (res) => {
            this.router.navigate(['/pages']);
          },
          error => {
            window.alert("username or password invalid !");
            this.loading = false;
          }
        );
      } else {
        window.alert("enter a valid email !");
        this.loading = false;
      }
    }
  }
  
  backToStore() {
    this.loading = true;
    document.location.href = 'https://store.happybeautyau.com/';
  }
}
