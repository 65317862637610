import { Injectable, PLATFORM_ID, Inject } from '@angular/core';
import { HttpInterceptor, HttpEvent, HttpHandler, HttpRequest } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Router } from '@angular/router';
import { isPlatformBrowser, isPlatformServer } from '@angular/common';
import { AuthService } from '../services/auth.service';
import { ToastrService } from 'ngx-toastr';

@Injectable({ providedIn: 'root' })
export class ErrorInterceptor implements HttpInterceptor {
    constructor(
        private router: Router,
        @Inject(PLATFORM_ID) private platformId: any,
        private authService: AuthService,
        private toastr: ToastrService,
    ) { }
    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(request).pipe(catchError(err => {
            if (err.status === 401) {
                // if (isPlatformBrowser(this.platformId)) { localStorage.removeItem("user"); localStorage.removeItem("shopId"); }
                localStorage.removeItem("user");
                localStorage.removeItem("shopId");
                localStorage.removeItem('unread_notification');
                this.authService.currentUserSubject.next(null);
                this.router.navigate(['/login']);
                this.toastr.error("Your session expired, please login again", 'Expired');
            }
            else if (err.error.message == "exceptions.shop_id_required") {
                this.router.navigate(['pages/shop/add']);
                this.toastr.warning("Please Add Shop before continue", 'Warning', {
                  timeOut: 3000
                });
            } 
            console.log(err);
            const error = err;
            return throwError(error);
        }));
    }
}
